import React from 'react';
import '../styles/ErrorStyling.css';

const InlineError = ({ errorMessage, setErrorMessage }) => {
  return (
    <div className='inline-error-container alert alert-danger d-flex justify-content-between align-items-center'>
      <span className='inline-error-message'>{errorMessage}</span>
      <button
        onClick={() => setErrorMessage(null)}
        className="error-close-btn"
      >
         <i className="bi bi-x-circle" ></i> 
      </button>
    </div>
  );
};

export default InlineError;
