import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../contexts/AuthContext';
import { useContext, } from 'react';
import { Link } from 'react-router-dom';
import '../styles/CampaignList.css'


const NavigationBar = () => {

  const {user, signOut} = useContext(AuthenticationContext);
  const navigate = useNavigate();

  return (
    <>
    {user ? 
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm" style={{backgroundColor: '#075ad4'} }>
    <div className="container-fluid">
      <a className="navbar-brand text-white" /* href="#" */>Sendwoosh</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-md-end" id="navbarsExample09">
        <ul className="navbar-nav" >
          <li className="nav-item">
            <Link className="btn btn-outline-light me-2" style={{fontWeight:"500"}} to="/dashboard"><i className="bi bi-columns-gap me-1" style={{WebkitTextStroke: "0.01em", fontSize: "1.1em"}}></i>Dashboard</Link>
          </li>
          <li className="nav-item">
            <Link className="btn btn-outline-light me-2" style={{fontWeight:"500"}} to="/Profile"><i className="bi bi-person-square me-1" style={{WebkitTextStroke: "0.01em", fontSize: "1.1em"}}></i>            Profile</Link>
          </li>
          <li className="nav-item">
          <Link className="btn btn-outline-light me-2" to="/dashboard" onClick={signOut}><i className="bi bi-box-arrow-right" style={{WebkitTextStroke: "0.01em", fontSize: "1.1em"}}></i></Link>
          </li>
        </ul>
      </div>
    </div>
  </nav> 
    : 
    null}
    
    </>
  )

}

export default NavigationBar
