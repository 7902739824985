import React, { useState, useEffect, useContext } from 'react';
import '../styles/CampaignList.css';

const SubscriptionPlanTable = ({ plans, openCheckout, currentSubscription }) => {
  
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
  
          {/* Free Plan Card */}
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className={`card border-primary shadow-sm text-center h-100 ${currentSubscription.currSubscriptionPlanPriceId === "None" ? "border-success" : ""}`}>
              <div className="card-header bg-primary text-white py-4">
                <h3 className="m-0">Free</h3>
              </div>
              <div className="card-body">
                <h4 className="text-dark">$0 / month</h4>
                <p>1 sender address</p>
                <p>100 email sends monthly</p>
              </div>
              <div className="card-footer bg-light py-3" style={{ minHeight: '4em', maxHeight: "4em" }}>
                {currentSubscription.currSubscriptionPlanPriceId === "None" ? (
                  <span className="badge bg-success w-100 py-2" style={{ fontSize: '1rem' }}>Current Plan</span>
                ) : (
                  <button
                    className="btn btn-primary w-100 py-2"
                    onClick={() => openCheckout("free-plan-id", currentSubscription.subscriptionId )}
                  >
                    Select Free
                  </button>
                )}
              </div>
            </div>
          </div>
  
          {/* Dynamic Plans */}
          {Object.entries(plans).map(([planName, plan]) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={planName}>
              <div className={`card shadow-sm text-center h-100 ${currentSubscription.currSubscriptionPlanPriceId === planName ? "border-success" : ""}`}>
                <div className="card-header bg-secondary text-white py-4">
                  <h3 className="m-0">{plan.name}</h3>
                </div>
                <div className="card-body">
                  <h4 className="text-dark">${plan.price} / month</h4>
                  <p>{plan.emailAddresses} email addresses</p>
                  <p>{plan.emailSends} email sends monthly</p>
                </div>
                <div className="card-footer bg-light py-3" style={{ minHeight: '4em', maxHeight: "4em" }}>
                  {currentSubscription.currSubscriptionPlanPriceId === plan.priceId ? (
                    <span className="badge bg-success w-100 py-2" style={{ fontSize: '1em' }}>Current Plan</span>
                  ) : (
                    <button
                      className="btn btn-primary w-100 py-2"
                      onClick={() => openCheckout(plan.priceId, currentSubscription.subscriptionId )}
                    >
                      Upgrade
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
  
        </div>
      </div>
    );
  };
  export default SubscriptionPlanTable