import React from 'react'
import { useEffect, useState, useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthContext';



const UserDetails = ({ userInfo }) => {


  return (
    <div className="container mt-4">
      {/* Main Card Container */}
      <div className="card p-4 shadow-sm border-1">
  
        {/* Header Section */}
        <div className="mb-4 text-center">
          <h3 className="d-flex align-items-center justify-content-center">
            <i className="bi bi-person-circle me-2 fs-2"></i> 
            <span>{userInfo.accountEmail}</span>
          </h3>
          <hr className="my-3" />
        </div>
  
        {/* Usage & Limits Section */}
        <div className="mb-4 text-center">
          <h4 className="mb-3 d-flex justify-content-center align-items-center">
            <i className="bi bi-bar-chart me-2"></i> Usage & Limits
          </h4>
          <div className="row justify-content-center">
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Monthly Sends</label>
              <h5>{userInfo.totalSendsPerPlan}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Remaining Sends</label>
              <h5>{userInfo.emailSendsRemaining}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Sender Addresses</label>
              <h5>{userInfo.senderAddresses}</h5>
            </div>
          </div>
        </div>
  
        {/* Billing Section */}
        <div className="mb-4 text-center">
          <h4 className="mb-3 d-flex justify-content-center align-items-center">
            <i className="bi bi-cash-coin me-2"></i> Billing
          </h4>
          <div className="row justify-content-center">
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Subscription Plan</label>
              <h5>{userInfo.subscriptionPlan}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Subscription Status</label>
              <h5>{userInfo.subscriptionStatus}</h5>
            </div>
            <div className="col-md-3 mb-3">
              <label className="text-muted small">Next Billing Date</label>
              <h5>{userInfo.nextBillingDate !== null ? userInfo.nextBillingDate : "Never"}</h5>
            </div>
          </div>
        </div>
  
        {/* Manage Subscription Section */}
        {userInfo.currSubscriptionPlanPriceId === "Free" ? null :         <div className="mb-4 text-center">
          <h4 className="mb-3 d-flex justify-content-center align-items-center">
          <i className="bi bi-credit-card me-2"></i> Manage Subscription
          </h4>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-outline-danger me-3 px-4"
              onClick={() => window.location.href = userInfo.managementUrls.cancel}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary px-4"
              onClick={() => window.location.href = userInfo.managementUrls.update_payment_method}
            >
              Update
            </button>
          </div>
        </div>}

      </div>
    </div>
  );
}

export default UserDetails
