export const hoverExpandButton = {
    position: 'absolute',
    bottom: '0', 
    width: '100%', 
    height: '66%', 
    backgroundColor: 'rgba(255, 255, 255, 0.8)', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 
  }

export const hiddenExpandButton = {
  ...hoverExpandButton,
  display: 'none', 

}

export const clickedExpandButton = {
  position: 'relative',
  width: '100%', 
  height: 'auto', 
  backgroundColor: 'rgba(255, 255, 255, 1)', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  marginTop: '0.5em' 
}