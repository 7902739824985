import React, { useState, useEffect, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import ManageCampaign from '../components/ManageCampaign'
import { supabase } from '../libraries/supabase';
import { AuthenticationContext } from '../contexts/AuthContext';
import PreviewMail from '../table_features/PreviewMail';
import EmailConversationModal from '../table_features/EmailConversationModal';
import StaticTextCell from '../table_features/StaticTextCell';
import { dateSort } from '../table_features/DateSorting';
import '../styles/CampaignManagement.css'
import { readableDateFormat } from '../utils/readableDateFormat';
import ErrorModal from '../errors/ErrorModal';


const SentCampaign = () => {
  const {user, userSession} = useContext(AuthenticationContext);
  const location = useLocation();
  const campaign_id = location.state.id;
  const [campaignData, setCampaignData] = useState(null);
  const [fetchError, setFetchError] = useState(null)
  const [loading, setLoading] = useState(true);
  const [senderEmailDetails, setSenderEmailDetails] = useState(null);
  const [campaignConfiguration, setCampaignConfiguration] = useState(null)

  
  const sentCampaignConfig = {
    headerFields: [ ],
    //WHY: This is called "variable" because we don't know beforehand how many followups, and which types of followups, 
    //the user has previously created for the campaign
    variableHeaderFields: ["Recipient", "Conversation Details", "First Send", "Last Reply", "Last Reply Date"],
    headers: {
      "Last Reply Date": {
        accessorKey: "Last Reply Date",
        header: "Last Reply Date",
        //sortUndefined: 'last',
        sortingFn: dateSort,
        cell: (props) => < StaticTextCell {...props}/>,
      }
    },
    cellRenderers: {
      "Preview Mail": (props) => <PreviewMail {...props} senderAddress={senderEmailDetails} />,
      "Conversation Details": (props) => <EmailConversationModal {...props} senderAddress={senderEmailDetails}/>,
      "First Send": (props) => < StaticTextCell {...props}/>,
      Recipient: (props) => < StaticTextCell {...props}/>,
      "Last Reply": (props) => < StaticTextCell {...props}/>,
      //"Last Reply Date": (props) => < StaticTextCell {...props}/>,
    },
    cellContent:{
    },
    campaignType: 'sent-management'
  }

  const fetchEmailAddressDetails = async (supabaseData) => {

    let emailId = {
      address_id: supabaseData[0].address_id,
      address: supabaseData[0].email_address,
      email_type: supabaseData[0].email_type
    }

    const {data, error} = await supabase
    .from('sender_email_addresses')
    .select(`
      address_id,
      created_at,
      email_address,
      user_id,
      email_type,
      first_name,
      last_name`)
    .eq('address_id', emailId.address_id)

    if (error) {
      setFetchError("Could not fetch email address.");
      //perhaps do a navigate here or something?
      console.error(error);
    }
    if (data) {
      setLoading(false)
      setSenderEmailDetails(data[0]);
      console.log(senderEmailDetails);
      console.log(data[0]);
      
      
    }
  } 


  const manipulateDataForTable = (data) => {
    const {email_threads, campaign_configuration} = data[0]
    let filteredEmailThreads = [];

    for (let i = 0; i < email_threads.length; i++) {

      
      let individualThread = {
        Recipient:email_threads[i].recipient_address,
        "Conversation Details": {
          length: email_threads[i].conversation_length,
          init_msg_id: email_threads[i].init_msg_id,
          address_id: email_threads[i].address_id,
          references_str: email_threads[i].references_str,
          in_reply_to: email_threads[i].in_reply_to,
          subject_line:email_threads[i].initial_email.subject
        },
        "First Send": email_threads[i].initial_email.text,
        "Last Reply": email_threads[i].last_reply ? email_threads[i].last_reply.text : "",
        "Last Reply Date": email_threads[i].last_reply_date ? readableDateFormat(email_threads[i].last_reply_date) : "",
        initMsgId: email_threads[i].init_msg_id
      }      

      //FUTURE TODO: ERROR: This code still feels fragile. Test extensively.
      for (let y = 0; y < email_threads[i].scheduled_followup_emails.length; y++) {

        let followup = email_threads[i].scheduled_followup_emails[y]

        if (!sentCampaignConfig.variableHeaderFields.includes(followup.followup_order)) {
          sentCampaignConfig.variableHeaderFields.push(followup.followup_order)
          individualThread[followup.followup_order] = followup.followup_body
          
      } else {
        individualThread[followup.followup_order] = followup.followup_body
      }
      }

      filteredEmailThreads.push(individualThread)
      
    }
    return filteredEmailThreads;
  }

  useEffect(() => {
    const fetchCampaignData = async () => {

        const { data, error } = await supabase
          .from('sent_campaigns')
          .select(`
              *,
              email_threads(
              *,
              scheduled_followup_emails(*)
    ) 
          `)
          .eq('campaign_id', campaign_id);

        if(error){
          setFetchError("Could not fetch campaign data"); 
          console.log(error);
        }
        if (data) {
          setFetchError(null);
          fetchEmailAddressDetails(data);
          
          data[0].campaign_configuration.campaignType = 'sent-management'
          data[0].campaign_configuration.initialSortingState = [
            { id: 'Last Reply Date', asc: true }
          ]
          setCampaignConfiguration(data[0].campaign_configuration)
          let tableData = manipulateDataForTable(data);

          
          setCampaignData({
            data: tableData,
            meta: {
              //fields: Object.keys(tableData[0])
              fields: sentCampaignConfig.variableHeaderFields
            }});
          
        }
    }
    fetchCampaignData();


  }, []);
  return (
    <>

    {!loading && campaignData ? 
    <ManageCampaign 
    campaignHeaderCellConfig={sentCampaignConfig} 
    senderEmailAddress={senderEmailDetails} 
    tableData={campaignData} 
    campaignConfig={campaignConfiguration}/>  
    : 
    null
    }
    {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}
    </>
    
  )
}

export default SentCampaign
