import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/EmailScheduler.css"
import React, { useState, forwardRef } from 'react';
import { format, add } from "date-fns";

const EmailScheduler = ({emailToSchedule, setCampaignConfiguration, description, lastChosenTime}) => {
  const [startDate, setStartDate] = useState(lastChosenTime ? new Date(lastChosenTime) : null);
  /* const [startDate, setStartDate] = useState(lastChosenTime ? lastChosenTime : null); */
  const now = new Date();
  const maxDate = add(now, { days: 8 });

  const fixStringForCSS = (str) => {
    return str
      .toLowerCase()               // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, '');    // Remove leading or trailing hyphens
  };
  const cssIdentifier = fixStringForCSS(emailToSchedule);

  const handleChange = (date) => {
    if (!date) {
      setStartDate(null);
      setCampaignConfiguration((prevConfig) => ({
        ...prevConfig,
        [emailToSchedule]: null,
      }));
      return;
    }
    setStartDate(date);
    const dateObject = {
      localDate: date,
      localDateFormatted: format(date, "yyyy-MM-dd'T'HH:mm"),
      ISODate: date.toISOString(),
      UTCDate: date.toUTCString()
    };
    setCampaignConfiguration((prevConfig) => ({
      ...prevConfig,
      [emailToSchedule]: dateObject,
    }));
  };

  const CustomDateInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button 
      title="Schedule the launch date of an email campaign or followup." 
      className={className || "btn btn-primary"} 
      onClick={onClick} ref={ref}>
        <i className="bi bi-calendar4-event"></i>
        {value || description}
      </button>
    ),
  );

  return (
    <div>
      <DatePicker
        id={cssIdentifier}
        selected={startDate}
        onChange={handleChange}
        showTimeSelect
        timeIntervals={10}
        minDate={now}
        maxDate={maxDate}
        minTime={new Date(now.setHours(0, 0, 0, 0))}
        maxTime={new Date(now.setHours(23, 50, 0, 0))}
        dateFormat="yyyy-MM-dd HH:mm"
        timeFormat="HH:mm"
        clearButtonTitle={"Clear btn"}
        isClearable={true}
        className="custom-datepicker-wrapper"
        customInput={<CustomDateInput className="table-bar-button" value={startDate ? format(startDate, "yyyy-MM-dd HH:mm") : ''} />}
      />
    </div>
  );
};

export default EmailScheduler;