import React from 'react';
//import './bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../src/sass/main.scss';

import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import App from './App';

const container = document.getElementById('root'); // Get the container element
const root = createRoot(container); // Create a root for the container
root.render(<App />); // Use the render method on the created root