import React, { useState, useEffect, useContext } from 'react';
import '../styles/ModalStyles.css'
import InlineError from '../errors/InlineError';

const EditEmail = ({ writeTextInAllRows }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [emailSubjectLine, setEmailSubjectLine] = useState('');
  const [inlineError, setInlineError] = useState(null);

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleTextChange = (text, columnKey, errorMethod) => {
    writeTextInAllRows(text, columnKey, errorMethod)
  }


  return (
    <div>
      <button
        title="Edit the email subject line and body."
        className="table-bar-button"
        onClick={() => toggleModal()} > <i className="bi bi-pencil-square"></i>
        Edit</button>

      {modalVisible ?
        <>
          <div className="modal-backdrop-custom"></div>
          <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Email Template</h5>
                </div>
                <div className="modal-body">
                  <div className="edit-email-form-group">
                    <label htmlFor="email-subject-line" className="edit-email-form-label">Email Subject Line</label>
                    <textarea
                      id="email-subject-line"
                      value={emailSubjectLine}
                      onChange={(e) => setEmailSubjectLine(e.target.value)}
                      className="edit-email-form-textarea"
                    />
                  </div>

                  <div className="edit-email-form-group">
                    <label htmlFor="email-body" className="edit-email-form-label">Email Body</label>
                    <textarea
                      id="email-body"
                      value={emailBody}
                      onChange={(e) => setEmailBody(e.target.value)}
                      className="edit-email-form-textarea"
                    />
                  </div>

                  <div>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i> {"Write {column header} to apply the cell content in the email subject and body."}</p>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i>{"Example: Hello {first name}, I love your {user site}. "}</p>
                  </div>

                  {inlineError && <InlineError errorMessage={inlineError} setErrorMessage={setInlineError} />}
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={() => handleTextChange(emailSubjectLine, "Subject Line", setInlineError)} className="btn btn-primary">Set Subject Line</button>
                  <button type="button" onClick={() => handleTextChange(emailBody, "Email Body", setInlineError)} className="btn btn-primary">Set Email Body</button>
                  <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        null
      }
    </div>
  )
}

export default EditEmail
