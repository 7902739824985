export const dateSort = (rowA, rowB, columnId) => {
    
    const dateA = new Date(rowA.getValue(columnId));
    const dateB = new Date(rowB.getValue(columnId));
    
    if (isNaN(dateA)) {
      return 1;
    }
    
    if (isNaN(dateB)) {
      return -1;
    }
      
    return dateB - dateA;
  };