import { supabase } from '../libraries/supabase.js'; 
import EmailingAPI from '../apis/EmailingAPI';

export const handleDeleteCampaign = async (row, userSession, setCampaigns, setFetchError, tableName, dataKey, event) => {
    event.stopPropagation();
    const deletableCampaign = {
        campaignId: row.campaign_id,
        tableName: tableName,
        userId: userSession.user.id,
        dataKey:dataKey
    }
    const deleteableData = {
        userSession,
        deletableCampaign
        
    }
    try {
        const response = await EmailingAPI.post("delete-campaign/", deleteableData)
        console.log(response);
        if(response.status === 200) {
                const { data, error } = await supabase
                .from(tableName)
                .select()
                .eq('user_id', userSession.user.id);
                if (error) {
                    setFetchError("Error fetching updated scheduled campaigns. Please refresh page")
                    return
                }
                if (data) {
                    setCampaigns((prevConfig) => ({
                        ...prevConfig,
                        [dataKey]: data,
                      }));
                }
        }
         
    } catch (error) {
        setFetchError("An unexpected error occured")
    }
    
};