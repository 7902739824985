import React, { useState, useEffect, useContext } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import { AuthenticationContext } from '../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import EmailingAPI from '../apis/EmailingAPI';
import InfoModal from './InfoModal';
import SubscriptionPlanTable from './SubscriptionPlanTable';
import paddlePriceIdToReadableString from '../utils/paddlePriceIdToReadableString';
import LoadingIcon from '../components/LoadingIcon';
import '../styles/CampaignList.css';


const PaddlePayments = ({ userAccountData }) => {
  const [paddle, setPaddle] = useState(null);
  const { user, userSession } = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null);
  const [buttonClickable, setIsButtonClickable] = useState(true);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("Monthly");
  const [loading, setLoading] = useState(false);
  const [newSubscriptionPriceId, setNewSubscriptionPriceId] = useState(null)
  const [modalConfig, setModalConfig] = useState({
    modalBody: "",
    modalHeader: "Upgrading Subscription",
    headerStyles: {
      backgroundColor: '#dc3545',
      color: 'white'
    },
    buttonMessage: "Upgrade",
    buttonStyles: "btn btn-primary",
    addressId: null,
    modalVisible: false
  });



  const monthlySubscriptions = {
    basic: { name: 'Basic', price: 9, emailAddresses: 'Unlimited', emailSends: 3000, priceId: process.env.REACT_APP_PADDLE_BASIC_PLAN_MONTHLY },
    pro: { name: 'Pro', price: 24, emailAddresses: 'Unlimited', emailSends: 25000, priceId: process.env.REACT_APP_PADDLE_PRO_PLAN_MONTHLY },
    expert: { name: 'Expert', price: 45, emailAddresses: 'Unlimited', emailSends: 100000, priceId: process.env.REACT_APP_PADDLE_EXPERT_PLAN_MONTHLY }
  };

  const yearlySubscriptions = {
    basic: { name: 'Basic', price: 7.2, emailAddresses: 'Unlimited', emailSends: 3000, priceId: process.env.REACT_APP_PADDLE_BASIC_PLAN_YEARLY },
    pro: { name: 'Pro', price: 19.2, emailAddresses: 'Unlimited', emailSends: 25000, priceId: process.env.REACT_APP_PADDLE_PRO_PLAN_YEARLY },
    expert: { name: 'Expert', price: 36, emailAddresses: 'Unlimited', emailSends: 100000, priceId: process.env.REACT_APP_PADDLE_EXPERT_PLAN_YEARLY }
  };

  // Initialize Paddle on mount
  useEffect(() => {
    initializePaddle({ environment: process.env.REACT_APP_PADDLE_ENVIRONMENT_TYPE, token: process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN })
      .then((paddleInstance) => setPaddle(paddleInstance))
      .catch((error) => {
        setFetchError("Failed to initialize Paddle");
        console.error('Failed to initialize Paddle:', error);
      });
  }, []);

  const upgradeSubscription = async (newSubId) => {
    setLoading(true)
    setModalConfig(prevConfig => ({
      ...prevConfig,
      modalVisible: false
    }));
    let sendableData = {
      userSession,
      userAccountData: { ...userAccountData, newSubscriptionPriceId: newSubId }
    }
    const response = await EmailingAPI.post('update-existing-paddle-subscription/', sendableData);
    console.log(response);
    if (response) {
      setLoading(false)
    }
  }

  const openCheckout = async (paddlePriceId, currentSubscriptionId) => {
    if (!buttonClickable || !paddle) {
      return;
    }
    setIsButtonClickable(false)

    //TODO: Test this for free users
    //TODO: Tell users their subscription has been upgraded
    if (currentSubscriptionId !== "None") {
      console.log(currentSubscriptionId);

      setModalConfig({
        modalBody: `Your current subscription and remaining quota will be closed. A new subscription for ${paddlePriceIdToReadableString(paddlePriceId)} will begin and you will be charged immediately.`,
        modalHeader: "Change subscription plan",
        headerStyles: {
          backgroundColor: '#0d6efd',
          color: 'white'
        },
        buttonMessage: "Update",
        buttonStyles: "btn btn-primary",
        addressId: null,
        modalVisible: true
      })
      setNewSubscriptionPriceId(paddlePriceId)

      return
    }

    paddle.Checkout.open({
      items: [{ priceId: paddlePriceId, quantity: 1 }],
      customData: {
        userId: userSession.user.id,
        userEmail: userSession.user.email,
        subscriptionPriceId: paddlePriceId,
        subLastChangeDate: userAccountData.subscriptionLastChangeDate
      }
    });
    setIsButtonClickable(true);
  };

  // Toggle between Monthly and Yearly plans
  const selectedPlans = subscriptionPeriod === 'Monthly' ? monthlySubscriptions : yearlySubscriptions;

  return (
    <>
      {loading ? <LoadingIcon /> : null}
      {/* Toggle between Monthly and Yearly */}
      <div className="d-flex justify-content-center mb-4 mt-4">
        <div className="btn-group" role="group" aria-label="Subscription Period Toggle">
          <button
            type="button"
            className={`btn btn-outline-primary ${subscriptionPeriod === "Monthly" ? "active" : ""}`}
            onClick={() => setSubscriptionPeriod("Monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`btn btn-outline-primary ${subscriptionPeriod === "Yearly" ? "active" : ""}`}
            onClick={() => setSubscriptionPeriod("Yearly")}
          >
            Yearly
          </button>
        </div>
      </div>

      {/* Pass the selected subscription plans to the SubscriptionPlan component */}
      <SubscriptionPlanTable
        plans={selectedPlans}
        openCheckout={openCheckout}
        currentSubscription={userAccountData} /* currentSubscription={userAccountData.currSubscriptionPlanPriceId } */ />

      {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />}
      {modalConfig.modalVisible &&
        <InfoModal
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          parentSpecificFunction={() => upgradeSubscription(newSubscriptionPriceId)}
          errorMessage={fetchError}
          setErrorMessage={setFetchError}
        />}
    </>
  );
};

export default PaddlePayments;