import React from 'react'
import { useState, useContext } from 'react'
import { useForm } from "react-hook-form"
import { supabase } from '../libraries/supabase';
import { AuthenticationContext } from '../contexts/AuthContext';
import EmailingAPI from '../apis/EmailingAPI';
import LoadingIcon from '../components/LoadingIcon';
import InlineError from '../errors/InlineError';
import InfoModal from './InfoModal';
import ReactDOM from 'react-dom';
import "../styles/ModalStyles.css"

const AddSMTPSenderAddress = ({ configuration, icon }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null)
  const [formOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [modalConfig, setModalConfig] = useState({
    modalBody: null,
    modalHeader: "",
    headerStyles: {},
    buttonMessage: "",
    buttonStyles: "",
    addressId: null,
    modalVisible: false
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  
  const onSubmit = async (formData) => {
    setIsFormOpen(true)
    let emailToSendObject = {
      editAddress: configuration.editAddress,
      senderAddress: {
        address_id: formData.email || configuration.addressToEdit,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email_address: formData.email || configuration.addressToEdit,
        email_type: 'smtp',
        credentials: {
          email_address: formData.email || configuration.addressToEdit,
          password: formData.password,
          SMTP_Host: formData.smtpHost,
          SMTP_Port: formData.smtpPort,
          IMAP_Host: formData.imapHost,
          IMAP_Port: formData.imapPort,
          IMAP_username: formData.email || configuration.addressToEdit,
          SMTP_username: formData.email || configuration.addressToEdit,
        }
      }

    }
    const senderEmailAddressData = {
      userSession,
      emailToSendObject,
    }

    try {
      const response = await EmailingAPI.post("add-email-address/", senderEmailAddressData)
      if (response.status === 200) {
        setIsFormOpen(false);
        setModalConfig({
          modalBody: `Operation succesful! Refresh page for changes to take effect.`,
          modalHeader: "Email address succesfully registered.",
          headerStyles: {
            backgroundColor: '#0d6efd',
            color: 'white'
          },
          buttonMessage: "Add new address",
          buttonStyles: "btn btn-primary",
          addressId: null,
          modalVisible: true
        })
      }
    } catch (error) {
      setFetchError(error?.response?.data?.message || "An error has occured")
    }
    setLoading(false)

  }

  const toggleModal = () => {
    setIsFormOpen(!formOpen)
    setModalConfig(prevConfig => ({
      ...prevConfig,
      modalVisible: false
    }));
  }
  return (
    <>
      {loading ? <LoadingIcon /> : null} 
      <button className={`${configuration.buttonStyle}`}
        style={configuration.buttonCss}
        onClick={() => toggleModal()}> {configuration.buttonText}
      </button> 

      {/* FUTURE TODO: maybe try to avoid using  ReactDOM.createPortal and try fixing the modal-appears-in-dropdown problem with standard css*/}
      {formOpen && ReactDOM.createPortal(
        <>
          <div className="modal-backdrop-custom"></div>
          <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog"  role="document">
              <div className="modal-content">
                <div className="modal-header" >
                  <h5 className="modal-title">
                    {configuration.editAddress === true && configuration.addressToEdit !== null ? 
                  `Edit details for: ${configuration.addressToEdit}` : "Connect your SMTP Account"}</h5>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {configuration.editAddress === true ?
                      null
                      : 
                      <div className="form-floating mb-2 d-flex flex-wrap" >
                      <input 
                      type="email" 
                      className="form-control"
                      
                      id="email" 
                      placeholder=""
                      {...register("email", { required: "Email Address is required" })}
                      aria-invalid={errors.email ? "true" : "false"}
                      />
                      <label htmlFor="email">Email address</label>
                      {errors.email?.type === "required" && (
                      <p className='text-danger' role="alert">Email is required</p> 
                      )}
                    </div>
                    }

                    
                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="text" 
                      className="form-control" 
                      id="formFirstName" 
                      placeholder="First Name"
                      {...register("firstName", { required: true })}
                      aria-invalid={errors.firstName ? "true" : "false"}
                      />
                      {errors.firstName?.type === "required" && (
                      <p className='text-danger' role="alert">First name is required</p>
                      )}
                      <label htmlFor="formFirstName">First Name</label>
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="text" 
                      className="form-control" 
                      id="formLastName" 
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                      />
                     {errors.lastName?.type === "required" && (
                      <p className='text-danger' role="alert">Last name is required</p>
                    )}
                      <label htmlFor="formLastName">Last Name</label>
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="number" 
                      className="form-control" 
                      id="smtpPort" 
                      placeholder="465"
                      {...register("smtpPort", { required: true, valueAsNumber: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                      />
                      {errors.smtpPort?.type === "required" && (
                      <p className='text-danger' role="alert">SMTP Port is required</p>
                    )}
                      <label htmlFor="smtpPort">SMTP Port</label>
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="number" 
                      className="form-control" 
                      id="imapPort" 
                      placeholder="993"
                      {...register("imapPort", { required: true, valueAsNumber: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                      />
                      <label htmlFor="imapPort">IMAP Port</label>
                      {errors.imapPort?.type === "required" && (
                      <p className='text-danger' role="alert">IMAP Port is required</p>
                    )}
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="text" 
                      className="form-control" 
                      id="imapHost" 
                      placeholder="IMAP Host"
                      {...register("imapHost", { required: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                      />
                      {errors.imapHost?.type === "required" && (
                      <p className='text-danger' role="alert">IMAP Host is required</p>
                    )}
                      <label htmlFor="imapHost">IMAP Host</label>
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="text" 
                      className="form-control" 
                      id="smtpHost" 
                      placeholder="SMTP Host"
                      {...register("smtpHost", { required: true })}
                      aria-invalid={errors.lastName ? "true" : "false"}
                      />
                      {errors.smtpHost?.type === "required" && (
                      <p className='text-danger' role="alert">SMTP Host is required</p>
                    )}
                      <label htmlFor="smtpHost">SMTP Host</label>
                    </div>

                    <div className="form-floating mb-2 d-flex flex-wrap">
                      <input type="password" 
                      className="form-control" 
                      id="password" 
                      placeholder="Password"
                      {...register("password", {
                        required: "App password is required",
                      })}
                      aria-invalid={errors.password ? "true" : "false"}
                      />
                      {errors.password && <p role="alert">{errors.password.message}</p>}
                      <label htmlFor="password">Password</label>
                    </div>

                    <div className="d-flex justify-content-end">
                    <button className="btn btn-primary me-2" type="submit">Submit</button>
                    <button type="button" className="btn btn-secondary close" aria-label="Close" onClick={() => toggleModal()}>
                      <span aria-hidden="true">Close</span>
                    </button>
                    </div>

                  </form>
                  {fetchError && <InlineError errorMessage={fetchError} setErrorMessage={setFetchError} />}
                </div>

              </div>
            </div>
          </div>
        </>,
      document.body
      )}
        {modalConfig.modalVisible && ReactDOM.createPortal(
        <InfoModal
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          parentSpecificFunction={() => toggleModal()}
          errorMessage={fetchError}
          setErrorMessage={setFetchError}
        />,
        document.body
        )}
    </>
  )
}

export default AddSMTPSenderAddress