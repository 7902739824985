import React from 'react'
import "../styles/ModalStyles.css"

const LoadingIcon = () => {
  return (
    <>
    <div className="modal-backdrop-custom"></div> 
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <div className="modal-dialog" role="document" style={{ margin: 'auto' }}>
    <div className="modal-content"  
            style={{
                maxWidth: 'fit-content',
                background: "white",
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5em', // Remove padding if any
                border: 'none', // Optional: remove border if you don't want it to affect the size
                boxSizing: 'content-box', // Ensure padding/border don't affect the size
              }}>
      <div className="loader">
      </div>

    </div>
  </div>
  </div>
  </>
  )
}

export default LoadingIcon
