import React, { useState, useRef, useEffect } from 'react';
import { hoverExpandButton, hiddenExpandButton, clickedExpandButton } from '../styles/TextCellBtnStyles';

const StaticTextCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue() || "";
  const [isExpanded, setIsExpanded] = useState(false);
  const textareaRef = useRef(null);
  const [isExpandBtnClicked, setIsExpandBtnClicked] = useState(false);

  const [expandButtonStyle, setExpandButtonStyle] = useState(hiddenExpandButton);

  const handleMouseEnter = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle(hoverExpandButton);
  };

  const handleMouseLeave = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle(hiddenExpandButton);
  };

  const handleButtonClick = () => {
    setIsExpandBtnClicked(!isExpandBtnClicked);
    setExpandButtonStyle(isExpandBtnClicked ? hiddenExpandButton : clickedExpandButton);
    handleReadMore();
  };

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    } else {
      textareaRef.current.style.height = 'inherit';
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = isExpanded ? `${textareaRef.current.scrollHeight}px` : 'inherit';
    }
  }, [isExpanded]);

  return (
    <div
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      style={{ position: 'relative', height: '100%' }}>
      <div className='table-static-cell'>
        <textarea
          ref={textareaRef}
          value={initialValue}
          readOnly
          style={{ overflow: 'hidden', border: 'none', background: 'transparent', resize: 'none', width: '100%', height: isExpanded ? 'auto' : 'inherit' }}
        />
      </div>
      <div style={expandButtonStyle}>
        <button
          className='btn btn-outline-primary editable-cell-button'
          onClick={() => handleButtonClick()}>
          {isExpanded ? <i className="bi bi-arrows-collapse-vertical"></i> : <i className="bi bi-arrows"></i>}
        </button>
      </div>
    </div>
  );
};

export default StaticTextCell;