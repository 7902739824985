import EmailingAPI from '../apis/EmailingAPI';

export const  handleSendEmails = async (campaignConfiguration, table, apiString, userSession) => {

    //TODO: Add another validation that checks if the followups actually text
    let emailToSendObject = {
      emailColumnKey: campaignConfiguration.emailColumnKey,
      emailSendTime: campaignConfiguration.emailSendTime || "Send Now",
      campaignName: campaignConfiguration.campaignName,
      senderAddress: campaignConfiguration.senderAddress,
      timeBetweenSends: campaignConfiguration.timeBetweenSends,
      tableColumnKeys: [],
      emailContacts: [],
      followUps: {
        "Follow-Up 1 Schedule": campaignConfiguration["Follow-Up 1 Schedule"],
        "Follow-Up 2 Schedule": campaignConfiguration["Follow-Up 2 Schedule"],
        "Follow-Up 3 Schedule": campaignConfiguration["Follow-Up 3 Schedule"]
      },
      campaignConfiguration: campaignConfiguration
    }

    const campaignData = {
      userSession,
      emailToSendObject
    }

    for (let i = 0; i < table.getCoreRowModel().flatRows.length; i++) {
      emailToSendObject.emailContacts.push(table.getCoreRowModel().flatRows[i].original)
    }
    for (let i = 0; i < table.getHeaderGroups()[0].headers.length; i++) {
      emailToSendObject.tableColumnKeys.push(table.getHeaderGroups()[0].headers[i].id);
    }
    try {
      const response = await EmailingAPI.post(apiString, campaignData);
      return response


    } catch (error) {
      if (error.response?.data?.message) {
        return error
      } 
      else {
        throw error
      }

    }
  }