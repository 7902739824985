export const columnInsertionOrder = (existingColumns, newColumn) => {
    const getFollowUpNumber = (key) => {
      const match = key.match(/^Follow-Up (\d+)$/);
      return match ? parseInt(match[1], 10) : null;
    };
  
    const newFollowUpNumber = getFollowUpNumber(newColumn.accessorKey);
    if (newFollowUpNumber === null) {
      return [...existingColumns, newColumn];
    }
  
    const newColumns = [];
    let inserted = false;
    for (let i = 0; i < existingColumns.length; i++) {
        //NOTE: This can be modified a bit further so that it adds some columns in certain order
      const existingFollowUpNumber = getFollowUpNumber(existingColumns[i].accessorKey);
      if (!inserted && existingFollowUpNumber !== null && existingFollowUpNumber > newFollowUpNumber) {
        newColumns.push(newColumn);
        inserted = true;
      }
      newColumns.push(existingColumns[i]);
    }
  
    if (!inserted) {
      newColumns.push(newColumn);
    }
  
    return newColumns;
  }