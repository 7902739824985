import React from 'react'
import { useNavigate } from 'react-router-dom';
import {  useState, useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CampaignList.css'
import { handleDeleteCampaign } from '../utils/handleDeleteCampaign';
import ErrorModal from '../errors/ErrorModal';
import { readableDateFormat } from '../utils/readableDateFormat';

const CampaignList = ({ initSentCampaigns, setUserCampaigns }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);

  const navigateToCampaign = (e, id) => {
    e.stopPropagation();
    navigate(`/sent-campaign/${id}`, { state: { id } })
  }
  initSentCampaigns.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return (
    <div className='container'>
      {user ?
        (<div>
          {initSentCampaigns.length > 0 ?

                  <table id="campaignList" className="container table table-hover">
                  <thead className='thead-light'>
                  <tr>
                    <th scope="col" title="Campaign name" className="col-2"><i className="bi bi-file-earmark-ruled"></i>{/* Campaign Name */}</th>
                    <th scope="col" title="Created on" className="col-3"><i className="bi bi-calendar-event"></i> {/* Created At */}</th>
                    <th scope="col" title="Sender address" className=" col-2"><i className="bi bi-envelope-at"></i>{/* Email Address */}</th>
                    <th scope="col" title="Contact count" className="col-1"><i className="bi bi-people"></i>{/* Contacts */}</th>
                    <th scope="col" title="Reply count" className="col-1"><i className="bi bi-chat-text"></i>{/* Replies */}</th>
                    <th scope="col" title="Campaign status" className="col-2"><i className="bi bi-info-square"></i>{/* Campaign Status */}</th>
                    <th scope="col" title="Delete campaign" className="col-1"> <i className="bi bi-trash"></i>{/* Delete */}</th>
                  </tr>
                </thead>
                    <tbody>
                      {initSentCampaigns.map((row, index) => (
                        <tr onClick={(e) => navigateToCampaign(e, row.campaign_id)} key={index}>
                          {/* <th scope='row' className="col-2">{row.campaign_name}</th> */}
                          <td  className="col-2">{row.campaign_name}</td>
                          <td className="col-3" >{readableDateFormat (row.created_at)}</td>
                          <td className="col-2">{row.email_address}</td>
                          <td className="col-1">{row.campaign_result ? row.campaign_result.length : row.email_contacts?.length}</td>
                          <td className="col-1">{row.total_replies}</td>
                          <td className="col-2">{row.campaign_status}</td>
                          <td className="col-1">
                            <button className="btn btn-danger rounded" onClick={(e) => handleDeleteCampaign(row, userSession, setUserCampaigns, setFetchError, 'sent_campaigns', 'sentCampaigns', e)}>
                            <i className="bi bi-x-circle"></i></button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
            :
            <p>Add an email address to create a campaign</p>}

        </div>

        )
        :
        null

      }
    {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}
    </div>
  )
}

export default CampaignList
