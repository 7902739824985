import React from 'react'
import { useState, useEffect, useContext } from 'react';


const ErrorModal = ({errorMessage, setErrorMessage}) => {
    const [error, setError] = useState(null)
    const [errorVisible, setErrorVisible] = useState(null);

    const closeError = () => {
        setErrorVisible(false)
        setErrorMessage(null)
      }

    useEffect(() => {
    if (errorMessage) {

        setError(errorMessage)
        setErrorVisible(true)
    }
    }, [errorMessage])
    
  return (
    <div>
    {error && errorVisible ? 
      <div className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}> {/* Ensure modal is visible when active */}
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{backgroundColor: "#dc3545"}}>
              <h5 className="modal-title text-white">An error has occured</h5>

            </div>
            <div className="modal-body">

              { error}

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => closeError()}>Close</button>
            </div>
          </div>
        </div>
      </div>
      :
      null}
    </div>
  )
}

export default ErrorModal
