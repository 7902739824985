import React from 'react'
import { useState, useContext } from 'react';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingIcon from '../components/LoadingIcon';
import ErrorModal from '../errors/ErrorModal';

const SaveCampaignDraftButton = ({table, campaignConfiguration}) => {
  const {user, userSession} = useContext(AuthenticationContext);
  const [fetchError, setFetchError] = useState(null);
  const [clickableButton, setClickableButton] = useState(true);
  const [loading, setLoading] = useState(false)
  const [isDraftSaved, setIsDraftSaved] = useState('clipboard');

  const navigate = useNavigate();

  const navigateToCampaign = (e, id) =>{
    e.stopPropagation();
    navigate(`/draft-campaign/${id}`,{state: {id}})
  }
   const handleSaveDraft = async (e) => {
    e.preventDefault();
    setLoading(true)
    setIsDraftSaved('clipboard');
    if (!clickableButton) {
      return
    }
    let emailToSendObject = {
      emailSendTime: campaignConfiguration.emailSendTime || "Send Now",
      campaignName: campaignConfiguration.campaignName,
      senderAddress: campaignConfiguration.senderAddress,
      emailContacts: [],
      tableColumnKeys:[],
      campaignConfiguration: campaignConfiguration
    }


    const campaignData = {
      userSession,
      emailToSendObject
    }

    for (let i = 0; i < table.getCoreRowModel().flatRows.length; i++) {
      emailToSendObject.emailContacts.push(table.getCoreRowModel().flatRows[i].original)
    }
    for (let i = 0; i < table.getHeaderGroups()[0].headers.length; i++) {
      emailToSendObject.tableColumnKeys.push(table.getHeaderGroups()[0].headers[i].id);
    }

    setClickableButton(false)    
    try {         
      const response = await EmailingAPI.post('save-campaign-draft/', campaignData);
      setFetchError(null)

      if (!campaignConfiguration.campaignId) {
        navigateToCampaign(e,response.data.message)
      }

      if (response.status === 200) {
        setIsDraftSaved('clipboard-success');
      }

      setClickableButton(true) 
      setLoading(false);

    } catch (error) {
      setFetchError(error.response?.data?.message || "There was an error submitting the information");
      setIsDraftSaved('clipboard-error');
      setClickableButton(true) 
      setLoading(false)
    }
  } 

  return (
    <div>
      <button 
      title="Save campaign as a draft so you can work on it later."
      onClick={(e)=> handleSaveDraft(e)} 
      className="table-bar-button">
      {isDraftSaved === 'clipboard' && <i className="bi bi-clipboard"></i>}
        {isDraftSaved === 'clipboard-success' && <i className="bi bi-clipboard-check"></i>}
        {isDraftSaved === 'clipboard-error' && <i className="bi bi-clipboard-x"></i>}
      Save</button>
      {fetchError ? 
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>
        :
        null
      }
      {loading ? <LoadingIcon/> : null}
    </div>
  )
}

export default SaveCampaignDraftButton