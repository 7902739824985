import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import ManageCampaign from '../components/ManageCampaign'
import TableDataUpload from '../components/TableDataUpload'
import { supabase } from '../libraries/supabase';
import EditableCell from '../table_features/EditableCell';
import PreviewMail from '../table_features/PreviewMail';
import '../styles/CampaignManagement.css'

const CampaignCreation = () => {
  const [tableData, setTableData] = useState(null);
  const location = useLocation();
  const [senderEmailDetails, setSenderEmailDetails] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [loading, setLoading] = useState(true);
  const {emailAddress} = location.state;


  const launchCampaignConfig = {
    headerFields: ["Subject Line","Email Body", "Preview Mail"],
    headers: {},
    cellRenderers: {
      "Subject Line": (props) => <EditableCell {...props} />,
      "Email Body": (props) => <EditableCell {...props} />,
      "Preview Mail": (props) => <PreviewMail {...props} senderAddress={senderEmailDetails} />
    },
    cellContent:{
/*       emailSubjectLine:"",
      emailBody: "", */
      sendEmailButtonId: null
    }
  }


  useEffect(() => {
    const fetchEmailAddressDetails = async () => {

      const {data, error} = await supabase
      .from('sender_email_addresses')
      .select(`
      address_id,
      created_at,
      email_address,
      user_id,
      email_type,
      first_name,
      last_name`)
      .eq('address_id', emailAddress.address_id)
  
      if (error) {
        setFetchError("Could not fetch email address credentials");
        //perhaps do a navigate here or something?
        console.error(error);
      }
      if (data) {
        setLoading(false)
        setSenderEmailDetails(data[0]);
      }

    }
    fetchEmailAddressDetails();
  }, [])


  const handleTableData = (data) => {
    setTableData(data);
  };
  return (
    <div /* className="table-responsive" */>
      {!tableData ? 
      <TableDataUpload onTableDataReceived={handleTableData} />
        : null
    }
      {tableData ? 
      <ManageCampaign campaignHeaderCellConfig={launchCampaignConfig} senderEmailAddress={senderEmailDetails}  tableData={tableData}/> :
      null}
      
    </div>
  )
}

export default CampaignCreation
