import React from 'react'
import LoginPage from '../components/LoginPage'

const Login = () => {
  return (
    <div>
      <LoginPage/>
    </div>
  )
}

export default Login
