import React from 'react'
import { useState, useEffect, useContext } from 'react';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from '../contexts/AuthContext';
import '../styles/ModalStyles.css'
import '../styles/CampaignManagement.css'

const PreviewMail = ({ getValue, row, column, table, senderAddress }) => {

  const { user, userSession } = useContext(AuthenticationContext);
  const [modalVisible, setModalVisible] = useState(false);
  const tableRow = row.original;
  const subjectLine = tableRow["Subject Line"];
  const emailBody = tableRow["Email Body"]
  const [testEmail, setTestEmail] = useState('')
  const emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  const [fetchError, setFetchError] = useState(null);

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const handleSendTestEmail = async (e) => {
    e.preventDefault();
    if (testEmail.match(emailRegex)) {
      console.log("test passed");
    } else {
      setFetchError("Please input a valid email address")
      return;
    }
    const emailToSendObject = {
      senderAddress: senderAddress,
      receiverDetails: {
        subjectLine: subjectLine,
        emailBody: emailBody,
        testEmail: testEmail
      }

    }

    const userSessionAndEmailTest = {
      userSession,
      emailToSendObject,
    }
    try {
      const response = await EmailingAPI.post('send-test-email/', userSessionAndEmailTest)
      setFetchError(null);
    } catch (error) {
      console.log(error);
      setFetchError(error)
    }
  }

  return (
    <div>
      <div className='conversation-modal-btn-div'>
        <button className='address-list-button' 
        style={{ fontSize: '1em',
          maxWidth: '50%',     
          textOverflow: 'ellipsis'   }} 
        onClick={() => toggleModal()}><i className="bi bi-receipt"></i>Preview Mail</button>
      </div>
      {modalVisible ?
        <>
          <div className="modal-backdrop-custom"></div>
          <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}> {/* Ensure modal is visible when active */}
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Preview and test email</h5>

                </div>
                <div className="modal-body">
                  <h6 className=" mb-3 mt-2 text-muted"><strong>Email From:</strong></h6>
                  <p className='email-content-bubble'>{senderAddress.email_address}</p>
                  <h6 className=" mb-3 mt-2 text-muted"><strong>Subject Line:</strong></h6>
                  <p className='email-content-bubble'>{subjectLine}</p>
                  <h6 className=" mb-3 mt-2 text-muted"><strong>Email Body:</strong></h6>
                  <p className='email-content-bubble'>{emailBody}</p>
                  <br />
                  <h6 className=" mb-3 mt-2 text-muted"><strong>Email address that will receive the test:</strong></h6>
                  <div>
                    <input
                      style={{ width: "60%" }}
                      name='testEmail'
                      id='testEmail'
                      value={testEmail}
                      type="text"
                      onChange={e => setTestEmail(e.target.value)} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={(e) => handleSendTestEmail(e)} >Send Test Email</button>
                  <button type="button" className="btn btn-secondary" onClick={() => toggleModal()} >Close</button>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        null
      }
    </div>
  )
}

export default PreviewMail