import React, { useState, useEffect, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import ManageCampaign from '../components/ManageCampaign'
import { supabase } from '../libraries/supabase';
import { AuthenticationContext } from '../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import PreviewMail from '../table_features/PreviewMail';
import '../styles/CampaignManagement.css'


const DraftCampaign = () => {
  //TODO: If user launches the campaign, they must be redirected to the sent campaign
  const location = useLocation();
  const campaign_id = location.state.id;
  const [campaignData, setCampaignData] = useState(null);
  const [fetchError, setFetchError] = useState(null)
  const [loading, setLoading] = useState(true);
  const [senderEmailDetails, setSenderEmailDetails] = useState(null);
  const [campaignConfiguration, setCampaignConfiguration] = useState(null)

  const draftCampaignConfig = {
    headerFields: [],
    headers: {},
    cellRenderers: {
      "Preview Mail": (props) => <PreviewMail {...props} senderAddress={senderEmailDetails} />
    },
    cellContent:{}
  }
  useEffect(() => {
    const fetchCampaignData = async () => {
        const { data, error } = await supabase
        .from('draft_campaigns')
        .select(`
          *,
          sender_email_addresses(*)
        `)
        .eq('campaign_id', campaign_id);
        if(error){
          setFetchError("Could not fetch campaign data"); 
          console.log(error);
        }
        if (data) {
          const {
            campaign_configuration: campaignConfiguration, 
            email_contacts: emailContacts,
            sender_email_addresses: senderEmailAddress,
            table_column_keys: tableColumnKeys
           } = data[0]
          setCampaignData({
            data: emailContacts,
            meta: {
              fields: tableColumnKeys
            }
          });
          setCampaignConfiguration(campaignConfiguration)
          setFetchError(null);
          setSenderEmailDetails(senderEmailAddress)
          setLoading(false)
        }
    }
    fetchCampaignData();


  }, []);

  return (
    <>

    {!loading && campaignData ? 
    <ManageCampaign campaignHeaderCellConfig={draftCampaignConfig} senderEmailAddress={senderEmailDetails} tableData={campaignData} campaignConfig={campaignConfiguration}/>  
    : 
    null
    }
    {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}
    </>
  )
}

export default DraftCampaign
