import React, { useState, useEffect, useRef } from 'react';
import { hoverExpandButton, hiddenExpandButton, clickedExpandButton } from '../styles/TextCellBtnStyles';

const EditableCell = ({ getValue, row, column, table }) => {
  const initialValue = getValue() || "";
  const [value, setValue] = useState(initialValue);
  const [isExpanded, setIsExpanded] = useState(false);
  const textareaRef = useRef(null);
  const [isExpandBtnClicked, setIsExpandBtnClicked] = useState(false);


  const [expandButtonStyle, setExpandButtonStyle] = useState(hiddenExpandButton)

  const handleMouseEnter = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle(hoverExpandButton);
  };

  const handleMouseLeave = () => {
    if (!isExpandBtnClicked) setExpandButtonStyle(hiddenExpandButton);
  };

  const handleButtonClick = () => {
    setIsExpandBtnClicked(!isExpandBtnClicked);
    setExpandButtonStyle(isExpandBtnClicked ? hiddenExpandButton : clickedExpandButton);
    handleReadMore()
  };

  const onBlur = () => {
    table.options.meta?.updateData(
      row.index,
      column.id,
      value
    );
  };

  useEffect(() => {
    setValue(initialValue);
    adjustTextareaHeight();
  }, [initialValue]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
 
      
    }
  };

  const handleReadMore = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    } else {
      textareaRef.current.style.height = 'inherit';
      /* adjustTextareaHeight(); */
    }
  };


  return (
    <div 
    onMouseEnter={() => handleMouseEnter()}
    onMouseLeave={() => handleMouseLeave()}
    style={{ position: 'relative', height: '100%' }}>
    <div className='table-editable-cell'>
      <textarea
        ref={textareaRef}
        value={value}
        type="text"
        onBlur={onBlur}
        onChange={e => setValue(e.target.value)}
        style={{ overflow: 'hidden' }}
      />
    </div>
    <div 
    style={expandButtonStyle}>
    <button 
      className='btn btn-outline-primary editable-cell-button'
      onClick={() =>handleButtonClick()}>
        {isExpanded ? <i className="bi bi-arrows-collapse-vertical"></i> : <i className="bi bi-arrows"></i>        }
      </button>
    </div>
    </div>
  );
};

export default EditableCell;