import React, {useState, createContext, useEffect, useCallback} from "react";
import { useNavigate} from 'react-router-dom';
import { supabase } from "../libraries/supabase";

export const AuthenticationContext = createContext({
  user: null,
  session: null,
  setUser: () => {},
  setUserSession: () => {},
  logOut: async () => {}
});

export const AuthenticationContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [userSession, setUserSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // useNavigate called within the component

  useEffect(() => {
    const setData = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;
        setUserSession(session);
        setUser(session?.user ?? null);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    const {data: authListener} = supabase.auth.onAuthStateChange((_event, userSession) => {
      setUserSession(userSession);
      setUser(userSession?.user ?? null);
      setLoading(false);
    })
    
  
    setData();

    return () => {
      authListener.subscription.unsubscribe();
    }
  }, []);

  const logOut = useCallback(async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      //TODO: improve error checking
      console.error('Logout failed', error);
    }
  }, [navigate]); 

  const value = {
    user,
    userSession,
    signOut: logOut,
  }

  return(
    <AuthenticationContext.Provider value = {value}>
      {!loading && props.children}
    </AuthenticationContext.Provider>
  )
}