const paddlePriceIdToReadableString = (priceId) => {

    switch (priceId) {
        case process.env.REACT_APP_PADDLE_BASIC_PLAN_MONTHLY:
            return "Basic Plan - Monthly";

        case process.env.REACT_APP_PADDLE_BASIC_PLAN_YEARLY:
            return "Basic Plan - Yearly";

        case process.env.REACT_APP_PADDLE_PRO_PLAN_MONTHLY:
            return "Pro Plan - Monthly";

        case process.env.REACT_APP_PADDLE_PRO_PLAN_YEARLY:
            return "Pro Plan - Yearly";

        case process.env.REACT_APP_PADDLE_EXPERT_PLAN_MONTHLY:
            return "Expert Plan - Monthly";

        case process.env.REACT_APP_PADDLE_EXPERT_PLAN_YEARLY:
            return "Expert Plan - Yearly";

        default:
            return "Free Plan"; 
    }
};

module.exports = paddlePriceIdToReadableString