import React from 'react'
import { useState, useContext } from 'react'
import { useForm } from "react-hook-form"
import { AuthenticationContext } from '../contexts/AuthContext';
import ReactDOM from 'react-dom';
import EmailingAPI from '../apis/EmailingAPI';
import InlineError from '../errors/InlineError';
import InfoModal from './InfoModal';
import LoadingIcon from './LoadingIcon';

const AddGmailWithAppPassword = ({configuration}) => {
  const {user, userSession} = useContext(AuthenticationContext);
  const [formOpen, setIsFormOpen] = useState(false);
  const [fetchError, setFetchError] = useState(null)
  const [loading, setLoading] = useState(null)
  const [formStage, setFormStage] = useState(1);
  const [modalConfig, setModalConfig] = useState({
    modalBody: null,
    modalHeader: "",
    headerStyles: {},
    buttonMessage: "",
    buttonStyles: "",
    addressId: null,
    modalVisible: false
  });


  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()


  const onSubmit = async (formData) => {
    setIsFormOpen(true)
    let emailToSendObject = {
      editAddress: configuration.editAddress,
      senderAddress: {
        address_id: formData.email || configuration.addressToEdit,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email_address: formData.email || configuration.addressToEdit,
        email_type: 'gmail',
        credentials: {
          email_address: formData.email || configuration.addressToEdit,
          password: formData.password,
        }
      }

    }
    const senderEmailAddressData = {
      userSession,
      emailToSendObject,
    }

    try {
      const response = await EmailingAPI.post("add-email-address/", senderEmailAddressData)
      if (response.status === 200) {
        setIsFormOpen(false);
        setModalConfig({
          modalBody: `Operation succesful! Refresh page for changes to take effect.`,
          modalHeader: "Email address succesfully registered.",
          headerStyles: {
            backgroundColor: '#0d6efd',
            color: 'white'
          },
          buttonMessage: "Add new address",
          buttonStyles: "btn btn-primary",
          addressId: null,
          modalVisible: true
        })
      }
    } catch (error) {
      setFetchError(error?.response?.data?.message || "An error has occured")
    }
    setLoading(false)
  }
  

  const toggleModal = () => {
    setIsFormOpen(!formOpen)
    setFormStage(1)
    setModalConfig(prevConfig => ({
      ...prevConfig,
      modalVisible: false
    }));
  }

  const previousFormStage = () => {
    if (formStage>1) {
      setFormStage(formStage-1);
    }
  }

  const nextFormStage = () => {
    setFormStage(formStage+1);
  }

  return (
    <>
      {loading ? <LoadingIcon /> : null} 
      <button className={`${configuration.buttonStyle}`}
        style={configuration.buttonCss}
        onClick={() => toggleModal()}> {configuration.buttonText}
      </button> 
      {formOpen && ReactDOM.createPortal(
        <>
        <div className="modal-backdrop-custom"></div>
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog"  role="document">
            <div className="modal-content">
              <div className="modal-header" >
                <h5 className="modal-title">
                  {configuration.editAddress === true && configuration.addressToEdit !== null ? 
                `Edit details for: ${configuration.addressToEdit}` : "Connect your Gmail Account"}</h5>
              </div>
              <div className="modal-body">

                {formStage === 1 ?
                 <>
                 <p>First, you need to enable IMAP access in your Gmail</p>
                 <hr />
                 <p>1. Open Gmail</p>
                 <p>2. Click the gear icon and press "See all settings"</p>
                 <p>3. Go to <a href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
                 target="_blank" rel="noopener noreferrer">
                     Forwarding and POP/IMAP</a></p>
                 <p>4. In the IMAP Access section, press Enable IMAP</p>
                 <p>5. Save Changes</p>
                 <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" onClick={() =>nextFormStage()}>I have enabled IMAP</button>
                  <button type="button" className="btn btn-secondary close" aria-label="Close" onClick={() => toggleModal()}>
                    <span aria-hidden="true">Close</span>
                  </button>
                  </div>
 
                 </>
                 :
                 null
                }
                {formStage === 2 ?
                 <>
                  <p>Next, you'll need to generate an app password</p>
                <p>To do this, you'll first need to activate 2-step verification</p>
                <p><a href="https://youtu.be/J4CtP1MBtOE?t=10"
                target="_blank" rel="noopener noreferrer">
                See tutorial video</a></p>
                <hr />
                <p>
                1. Open your Google account <a href="https://myaccount.google.com/security"
                target="_blank" rel="noopener noreferrer">
                Security Settings</a>
                </p>
                <p>
                2. Enable <a href="https://myaccount.google.com/signinoptions/two-step-verification"
                target="_blank" rel="noopener noreferrer">
                2 step verification </a>
                </p>
                <p>
                2. Create an <a href="https://myaccount.google.com/apppasswords"
                target="_blank" rel="noopener noreferrer">
                App password</a>
                </p>
                <p>Note: Choose 'Other' for both App and Device</p>
                
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" onClick={() =>nextFormStage()}>Next</button>
                  <button className="btn btn-secondary me-2" onClick={()=> previousFormStage() }> Back</button>
                  </div>
                 </>
                 :
                 null
                }
                {formStage === 3 ?
                  <form onSubmit={handleSubmit(onSubmit)}>
                  {configuration.editAddress === true ?
                    null
                    : 
                    <div className="form-floating mb-2 d-flex flex-wrap" >
                    <input 
                    type="email" 
                    className="form-control"
                    
                    id="email" 
                    placeholder=""
                    {...register("email", { required: "Email Address is required" })}
                    aria-invalid={errors.email ? "true" : "false"}
                    />
                    <label htmlFor="email">Email address</label>
                    {errors.email?.type === "required" && (
                    <p className='text-danger' role="alert">Email is required</p> 
                    )}
                  </div>
                  }

                  
                  <div className="form-floating mb-2 d-flex flex-wrap">
                    <input type="text" 
                    className="form-control" 
                    id="formFirstName" 
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    aria-invalid={errors.firstName ? "true" : "false"}
                    />
                    {errors.firstName?.type === "required" && (
                    <p className='text-danger' role="alert">First name is required</p>
                    )}
                    <label htmlFor="formFirstName">First Name</label>
                  </div>

                  <div className="form-floating mb-2 d-flex flex-wrap">
                    <input type="text" 
                    className="form-control" 
                    id="formLastName" 
                    placeholder="Last Name"
                    {...register("lastName", { required: true })}
                    aria-invalid={errors.lastName ? "true" : "false"}
                    />
                    {errors.lastName?.type === "required" && (
                    <p className='text-danger' role="alert">Last name is required</p>
                  )}
                    <label htmlFor="formLastName">Last Name</label>
                  </div>

                  <div className="form-floating mb-2 d-flex flex-wrap">
                    <input type="password" 
                    className="form-control" 
                    id="password" 
                    placeholder="Password"
                    {...register("password", {
                      required: "App password is required",
                    })}
                    aria-invalid={errors.password ? "true" : "false"}
                    />
                    {errors.password && <p role="alert">{errors.password.message}</p>}
                    <label htmlFor="password">Password</label>
                  </div>

                  <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" type="submit">Submit</button>
                  <button type="button" className="btn btn-secondary close" aria-label="Close" onClick={() => toggleModal()}>
                    <span aria-hidden="true">Close</span>
                  </button>
                  </div>

                  </form>
                 :
                 null
                }

                {fetchError && <InlineError errorMessage={fetchError} setErrorMessage={setFetchError} />}
              </div>

            </div>
          </div>
          </div>
          

        </>,
      document.body
      )}
        {modalConfig.modalVisible && ReactDOM.createPortal(
        <InfoModal
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          parentSpecificFunction={() => toggleModal()}
          errorMessage={fetchError}
          setErrorMessage={setFetchError}
        />,
        document.body
        )}
    </>
  )
}

export default AddGmailWithAppPassword
