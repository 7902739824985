import React from 'react'

const SelectEmailColumnKey = ({table, setCampaignConfiguration, lastChosenColumnKey}) => {

  const columnIds = table.getAllFlatColumns().map(column => column.id);

  const handleEmailColumnSelection = ( key) => {
    setCampaignConfiguration((prevConfig) => ({
        ...prevConfig,
        emailColumnKey: key,
      }));
  }

  return (

    <div className="dropdown">
  <button 
  title="Choose the column that contains the email addresses"
  className="table-bar-button dropdown-toggle" 
  type="button" id="emailColumnDropdown" 
  data-bs-toggle="dropdown" aria-expanded="false">
  <i className="bi bi-at"></i>
    {lastChosenColumnKey ? lastChosenColumnKey  : "Column"}
  </button>
  <ul className="dropdown-menu" aria-labelledby="emailColumnDropdown">
      {columnIds.map((columnId) => {
          if (columnId === "Preview Mail" 
            || columnId === "Email Body" 
            || columnId === "Subject Line"
            || columnId.includes("Follow-Up")
          ) {
              return null;
          }
          return (

          <li 
          className='dropdown-list-item'
          key={columnId}>
          <button  value={columnId} onClick={()=> handleEmailColumnSelection( columnId)}>
              {columnId}
          </button>
          </li>
          )})}
  </ul>
</div>

  )
}

export default SelectEmailColumnKey
