import React from 'react'
import { useNavigate } from 'react-router-dom';
import {  useState, useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthContext';
import { handleDeleteCampaign } from '../utils/handleDeleteCampaign';
import '../styles/CampaignList.css'
import ErrorModal from '../errors/ErrorModal';
const {  formatDistance } = require("date-fns");

const ScheduledCampaigns = ({ initScheduledCampaigns, setUserCampaigns }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);
  const [campaigns, setCampaigns] = useState(initScheduledCampaigns.length > 0 ? initScheduledCampaigns : null);

  const nowUtcTime = new Date().toISOString();


  const timeUntilSend = (now, futureTime) => {
    return formatDistance(now, futureTime)
  }

  initScheduledCampaigns.sort((a, b) => {
    const now = new Date(); // Get the current date and time
    return Math.abs(new Date(a.sending_time) - now) - Math.abs(new Date(b.sending_time) - now);
  });
  

  const navigateToCampaign = (e, id) => {
    e.stopPropagation();
    navigate(`/scheduled-campaign/${id}`, { state: { id } })
  }

  return (
    <div className='container'>
      {user ?
        (<div>
          <div>
          </div>

          {initScheduledCampaigns.length > 0  ?

            <table id="campaignList" className="container table table-hover">
              <thead className='thead-light'>
                <tr>
                  <th scope="col" title="Campaign name" className="col-1"><i className="bi bi-file-earmark-ruled"></i>{/* Campaign Name */}</th>
                  <th scope="col" title="Launches in" className="col-1"><i className="bi bi-hourglass-top"></i> {/* Sending In */}</th>
                  <th scope="col" title="Sender address" className="col-1"><i className="bi bi-envelope-at"></i>{/* Email Address */}</th>
                  <th scope="col" title="Contact count" className="col-1"><i className="bi bi-people"></i>{/* Contacts */}</th>
                  <th scope="col" title="Delete campaign" className="col-1"><i className="bi bi-trash"></i>{/* Delete */}</th>
                </tr>
              </thead>
              <tbody>
                {initScheduledCampaigns.map((row, index) => {
                  return (
                    <tr onClick={(e) => navigateToCampaign(e, row.campaign_id)} key={index}>
                      {/* <th scope='row' className="col-1">{row.campaign_name}</th> */}
                      <td  className="col-1">{row.campaign_name}</td>
                      <td className="col-1">in {timeUntilSend(nowUtcTime, row.sending_time)}</td>
                      <td className="col-1">{row.email_address}</td>
                      <td className="col-1">{row.email_to_send_object.emailContacts.length}</td>
                      <td className="col-1">
                        <button className="btn btn-danger rounded" onClick={(e) => handleDeleteCampaign(row, userSession, setUserCampaigns, setFetchError, 'scheduled_campaigns', 'scheduledCampaigns', e)}>
                        <i className="bi bi-x-circle"></i></button>
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </table>
            :
            <p>No scheduled campaigns.</p>}

        </div>

        )
        :
        (<>
          <h1>Bad</h1>
        </>)

      }
      {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />}
    </div>
  )
}

export default ScheduledCampaigns