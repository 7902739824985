import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CampaignList.css'
import ErrorModal from '../errors/ErrorModal';
import { handleDeleteCampaign } from '../utils/handleDeleteCampaign';
import { readableDateFormat } from '../utils/readableDateFormat';

const DraftCampaignList = ({initDraftCampaigns, setUserCampaigns}) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);
  
  const navigateToCampaign = (e, id) => {
    e.stopPropagation();
    navigate(`/draft-campaign/${id}`, { state: { id } })
  }

  initDraftCampaigns.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return (
    <div className='container'>
      {user ?
        (<div>
          <div>
          </div>
          
          {initDraftCampaigns.length > 0 ?

                  <table id="campaignList" className="container table table-hover" /* "table table-hover justify-content-center custom-table-bg" */>
                  <thead className='thead-light'>
                  <tr>
                  <th scope="col" title="Campaign name" className="col-1"><i className="bi bi-file-earmark-ruled"></i>{/* Campaign Name */}</th>
                  <th scope="col" title="Created on" className="col-1"><i className="bi bi-calendar-event"></i> {/* Created At */}</th>
                  <th scope="col" title="Sender address" className="col-1"><i className="bi bi-envelope-at"></i>{/* Email Address */}</th>
                  <th scope="col" title="Contact count" className="col-1"><i className="bi bi-people"></i>{/* Contacts */}</th>
                  <th scope="col" title="Delete campaign" className="col-1"> <i className="bi bi-trash"></i>{/* Delete */}</th>
                  </tr>
                </thead>
                    <tbody>
                      {initDraftCampaigns.map((row, index) => (
                        <tr onClick={(e) => navigateToCampaign(e, row.campaign_id)} style={{ cursor: 'pointer' }} key={index}>
                          <td  className="col-2">{row.campaign_name}</td>
                          <td className="col-1">{readableDateFormat(row.created_at)}</td>
                          <td className="col-1">{row.email_address}</td>
                          <td className="col-1">{row.email_contacts.length}</td>
                          <td className="col-1">
                            <button className="btn btn-danger rounded" onClick={(e) => handleDeleteCampaign(row, userSession, setUserCampaigns, setFetchError, 'draft_campaigns', 'draftCampaigns', e )}>
                            <i className="bi bi-x-circle"></i></button>
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
            :
            <p>No sent campaigns</p>}

        </div>

        )
        :
        (<>
          <h1>Bad</h1>
        </>)

      }
  {fetchError && <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError}/>}
    </div>
  )
}

export default DraftCampaignList