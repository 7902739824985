export const modifyEmailSubjectAndBody = (tableRow, emailStrings, setError) => {
    let finalEmailString = emailStrings;
    const templateRegex = /{[\w\s:=~!?]+}/g;
    const emailPlaceholderMatches = emailStrings.match(templateRegex);
    
    if (emailPlaceholderMatches) {
      emailPlaceholderMatches.forEach((word) => {
        const objectKey = word.slice(1, -1);
        if (!tableRow.hasOwnProperty(objectKey)) {
          setError(`The placeholder ${objectKey} does not match any column headers. Please verify to prevent mistakes.`);
          
        }
        const dynamicRegex = new RegExp(word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        finalEmailString = finalEmailString.replace(dynamicRegex, tableRow[objectKey] || '');
      });
  
      return finalEmailString;
    } else {
      return emailStrings;
    }
  };

