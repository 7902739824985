import React from 'react'

const ColumnVisibility = ({table}) => {
  return (
    <div>
    <div className='ms-2 mt-2 mb-2'>
        <div className="col-auto ">
          <div className="d-inline-flex border rounded-pill p-1" 
          style={{backgroundColor: "#eef2fa", fontSize:"0.9em"}}>
            <div className="d-flex flex-nowrap overflow-auto mt-1">
            <div className="form-check ms-3 me-3 ">
              <input
                className="form-check-input"
                type="checkbox"
                id="toggleAll"
                checked={table.getIsAllColumnsVisible()}
                onChange={table.getToggleAllColumnsVisibilityHandler()}
              />
              <label className="form-check-label" htmlFor="toggleAll">
                Toggle All
              </label>
            </div>
            <div className="vr me-3"></div>
              {table.getAllLeafColumns().map(column => (
                <div key={column.id} className="form-check me-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`check-${column.id}`}
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                  />
                  <label className="form-check-label" htmlFor={`check-${column.id}`}>
                    {column.id}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
    </div>
    </div>
  );
}

export default ColumnVisibility
