export const validateCampaignConfig = ( campaignConfiguration) => {
    let result = {
      validConfig: false,
      error: "Campaign is not configured correctly."
    }

    if (!campaignConfiguration['Subject Line']) {
      result = {
        validConfig: false,
        error: "Campaign does not have a subject line."
      }
      return result
    }

    if (!campaignConfiguration['Email Body']) {
      result = {
        validConfig: false,
        error: "Campaign does not have a body"
      }
      return result
    }

    if (!campaignConfiguration.emailColumnKey) {
      result = {
        validConfig: false,
        error: "Please choose the email column"
      }
      return result
    }

    result = {
      validConfig: true,
      error: null
    }
    return result
  }