import React, { useEffect } from 'react';
import CampaignList from './CampaignList';
import ScheduledCampaigns from './ScheduledCampaigns';
import DraftCampaignList from './DraftCampaignList';
import '../styles/CampaignList.css'

const TabBar = ({userData, setUserData}) => {
    useEffect(() => {
        const triggerTabList = [].slice.call(document.querySelectorAll('#pills-tab button'));
        triggerTabList.forEach(function (triggerEl) {
            const tabTrigger = new window.bootstrap.Tab(triggerEl);

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault();
                tabTrigger.show();
            });
        });
    }, []);

    return (
        <div>
            <ul className="nav nav-pills mb-3 mt-3  border" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                    <button className="nav-link active campaign-type-tab" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                    <i className="bi bi-send icon-campaign-list-btn"></i>  Sent
                        </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link campaign-type-tab" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                    <i className="bi bi-calendar-check icon-campaign-list-btn"></i> Scheduled
                        </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link campaign-type-tab" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                    <i className="bi bi-file-earmark-text icon-campaign-list-btn"></i> Draft
                        </button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <CampaignList initSentCampaigns={userData.sentCampaigns} setUserCampaigns={setUserData}/>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <ScheduledCampaigns initScheduledCampaigns={userData.scheduledCampaigns} setUserCampaigns={setUserData}/>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <DraftCampaignList initDraftCampaigns={userData.draftCampaigns} setUserCampaigns={setUserData}/>
                </div>
            </div>
        </div>
    );
}

export default TabBar
