import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmailingAPI from '../apis/EmailingAPI';
import { AuthenticationContext } from './../contexts/AuthContext';
import ErrorModal from '../errors/ErrorModal';
import InfoModal from '../components/InfoModal';
import LoadingIcon from '../components/LoadingIcon';
import { readableDateFormat } from '../utils/readableDateFormat';
import { handleSendEmails } from '../utils/handleSendEmails';
import { validateFollowUpSchedules } from '../utils/validateFollowUpSchedules';
import { validateCampaignConfig } from '../utils/validateCampaignConfig';

const LaunchCampaignButton = ({ table, campaignConfiguration }) => {
  const { user, userSession } = useContext(AuthenticationContext);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [modalConfig, setModalConfig] = useState({
    modalBody: null,
    modalHeader: "",
    headerStyles: {},
    buttonMessage: "",
    buttonStyles: "",
    addressId: null,
    modalVisible: false
  });

  useEffect(() => {
    const initModalConfig = {
      modalBody: (

        <div>
          <label><strong>Campaign Name</strong></label>
          <p>{`${campaignConfiguration.campaignName}`}</p>

          <label><strong>Email Column Key</strong></label>
          <p>{`${campaignConfiguration.emailColumnKey}`}</p>

          <label><strong>Campaign Send Time</strong></label>
          <p>{campaignConfiguration.emailSendTime ? `${readableDateFormat(campaignConfiguration.emailSendTime.localDateFormatted)}` 
          : "Send immediately"}</p>

          <label><strong>Email Body</strong></label>
          <p>{`${campaignConfiguration["Email Body"]}`}</p>

          <label><strong>Email Subject Line</strong></label>
          <p>{`${campaignConfiguration["Subject Line"]}`}</p>

          <label><strong>Follow-Up 1 </strong></label>
          <p>{campaignConfiguration["Follow-Up 1"] ? `${campaignConfiguration["Follow-Up 1"]}` : "Follow-Up 1 text not set"}</p>
          <p>{campaignConfiguration["Follow-Up 1 Schedule"] ? 
          `${readableDateFormat(campaignConfiguration["Follow-Up 1 Schedule"].localDateFormatted)}` 
          : "Follow-Up 1 not scheduled"}</p>

          <label><strong>Follow-Up 2 </strong></label>
          <p>{campaignConfiguration["Follow-Up 2"] ? `${campaignConfiguration["Follow-Up 2"]}` : "Follow-Up 2 text not set"}</p>
          <p>{campaignConfiguration["Follow-Up 2 Schedule"] ? 
          `${readableDateFormat(campaignConfiguration["Follow-Up 2 Schedule"].localDateFormatted)}` 
          : "Follow-Up 2 not scheduled"}</p>

          <label><strong>Follow-Up 3 </strong></label>
          <p>{campaignConfiguration["Follow-Up 3"] ? `${campaignConfiguration["Follow-Up 3"]}` : "Follow-Up 3 text not set"}</p>
          <p>{campaignConfiguration["Follow-Up 3 Schedule"] ? 
          `${readableDateFormat(campaignConfiguration["Follow-Up 3 Schedule"].localDateFormatted)}` 
          : "Follow-Up 3 not scheduled"}</p>

        </div>
      ),
      modalHeader: "Review Campaign",
      headerStyles: {
        backgroundColor: '#0000FF',
        color: 'white'
      },
      buttonMessage: "Launch Campaign",
      buttonStyles: "btn btn-primary",
      addressId: null,
      modalVisible: false
    };
  
    setModalConfig(initModalConfig);
  }, [campaignConfiguration]);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(`/dashboard/`)
  }


  const handleSendClick = (e) => {
    //console.log(campaignConfiguration);

    e.preventDefault();
    let configCheck = validateCampaignConfig(campaignConfiguration)
    if (configCheck.validConfig === false) {
      setFetchError(configCheck.error)
      return 
    }

    if (validateFollowUpSchedules(campaignConfiguration) !== true) {
      setFetchError(validateFollowUpSchedules(campaignConfiguration))
      return
    }
    setModalConfig(prevConfig => ({
      ...prevConfig,
      modalVisible: true
    }));
  }


  const handleLaunchCampaign = async () => {
    setModalConfig(prevConfig => ({
        ...prevConfig,
        modalVisible: false
      }));
    
    setLoading(true)
    try {
      const result = await handleSendEmails(campaignConfiguration,table, "send-email/", userSession)
      if (result) {
        console.log(result); 
        /* navigateToDashboard() */
      }
      if (result?.status === 200) {
        setModalConfig({
          modalBody: result.data.message ?  (
            <div>
              <p>{result.data.message}</p>
            </div>
          ) :
          <div>
              <p>Operation succesful</p>
           </div>
          ,
          modalHeader: result.data.headline || "Success!",
          headerStyles: {
            backgroundColor: result.data?.backgroundColor || "#198754",
            color: 'white'
          },
          buttonMessage: "Cancel",
          buttonStyles: "btn btn-primary",
          addressId: null,
          modalVisible: true,
          showActionButton: false
        })
        setFetchError(null)
        setLoading(false)
      }
      if (result?.status !== 200) {
          console.log(result);
          setModalConfig({
            modalBody: result.response.data.message ?  (
              <div>
                <p>{result.response.data.message}</p>
              </div>
            ) :
            <div>
                <p>Operation failed</p>
             </div>
            ,
            modalHeader: result.response.data.headline || "Error",
            headerStyles: {
              backgroundColor: '#ffc107',
              color: 'black'
            },
            buttonMessage: "Cancel",
            buttonStyles: "btn btn-primary",
            addressId: null,
            modalVisible: true,
            showActionButton: false
          })
          setFetchError(null)
          setLoading(false)
      }
    } catch (error) {
      //console.log(error);
      setFetchError("Server error, campaign could not be sent. Please try again later")
      setLoading(false)
    }
    
  }


  return (
    <div>
      <button className="table-bar-button"
        title={campaignConfiguration.campaignType === 'sent-management' ? 'Update the followups and other details of the campaign.' : 'Review and launch the campaign.'}
        onClick={(e) => handleSendClick(e)}><i className="bi bi-arrow-right-square"></i>
        {campaignConfiguration.campaignType === 'sent-management' ? "Update Campaign" : "Launch Campaign"}</button>
      {fetchError ?
        <ErrorModal errorMessage={fetchError} setErrorMessage={setFetchError} />
        :
        null
      }
      {loading ? <LoadingIcon /> : null}
      {modalConfig.modalVisible &&
        <InfoModal
          setModalConfig={setModalConfig}
          modalConfig={modalConfig}
          parentSpecificFunction={() => handleLaunchCampaign()}
          errorMessage={fetchError}
          setErrorMessage={setFetchError}
        />}
    </div>
  )
}

export default LaunchCampaignButton
