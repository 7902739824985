import React, { useState, useEffect, useContext } from 'react';
import EditableCell from './EditableCell';
import EmailScheduler from './EmailScheduler';
import InlineError from '../errors/InlineError';
import '../styles/ModalStyles.css'
const { format } = require("date-fns")


const FollowUpColumn = ({ table, addColumnToTable, followUpId,
  writeTextInAllRows, removeColumnFromTable, setCampaignConfiguration, campaignConfiguration }) => {

  let followUpTimeId = followUpId.concat(" Schedule");
  const initialDisplayDate = campaignConfiguration[followUpTimeId] ?
    format(new Date(campaignConfiguration[followUpTimeId].localDateFormatted), 'EEE, MMMM d, HH:mm') : "Follow-Up Not Scheduled"

  const [modalVisible, setModalVisible] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [displayDate, setDisplayDate] = useState(initialDisplayDate)

  const [followupConfiguration, setFollowupConfiguration] = useState({
    emailBody: campaignConfiguration[followUpId],
    [followUpTimeId]: campaignConfiguration[followUpTimeId]
  });


  const doesTableHaveFollowUp = (table) => {
    //TODO: This code feels fragile since you assume the headers you need are at index [0].
    //also, implement a null check or something.
    let tableCols = table.getHeaderGroups()[0].headers
    return tableCols.some(col => col.id === followUpId);
  }


  let chosenTime = followupConfiguration[followUpTimeId];

  const dateParser = (dateObject) => {
    if (!dateObject) {
      setDisplayDate("Follow-Up Not Scheduled")
      return
    }
    let formattedDate = format(new Date(dateObject.localDateFormatted), 'EEE, MMMM d, HH:mm');
    setDisplayDate(formattedDate)
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
    if (modalVisible) {
      setFetchError(null)
    }

  }

  const handleTextChange = (text, columnKey) => {

    writeTextInAllRows(text, columnKey)
  }


  const handleFollowupRemove = () => {
    removeColumnFromTable(followUpId)
    setFollowupConfiguration({
      emailBody: '',
      [followUpTimeId]: null
    })
    setDisplayDate("Follow-Up Not Scheduled")
    setCampaignConfiguration((prevConfig) => ({
      ...prevConfig,
      [followUpTimeId]: null,
      [followUpId]: ''
    }));

  }
  const handleColumnAdition = () => {

    if (!followupConfiguration.emailBody) {
      setFetchError("Followup email doesn't have an email body!")
      return
    }

    if (!followupConfiguration[followUpTimeId]) {
      setFetchError("Followup email isn't scheduled. Please select a sending date!")
      return
    }
    setFetchError(null)
    let followUp = {
      accessorKey: followUpId,
      header: () => followUpId,
      cell: ((props) => <EditableCell {...props} />),
      enableSorting: false
    }
    if (!doesTableHaveFollowUp(table)) {
      addColumnToTable(followUp)
    } else {
      setFetchError("Follow-up already exists.")
    }

    handleTextChange(followupConfiguration.emailBody, followUpId);
    setCampaignConfiguration((prevConfig) => ({
      ...prevConfig,
      [followUpTimeId]: followupConfiguration[followUpTimeId],
    }));
    dateParser(chosenTime)

  }
  return (
    <div >
      <div>
        <button
          className="table-bar-button"
          title="Configure a follow-up email"
          onClick={() => toggleModal()}>
          <i className="bi bi-arrow-return-right"></i>
          {followUpId} {!campaignConfiguration[followUpTimeId]?.localDateFormatted && !campaignConfiguration[followUpId] ? <i className="bi bi-circle ms-1"></i> : <i className="bi bi-check-circle-fill ms-1"></i>
          }</button>


      </div>


      {modalVisible ?
        <>
          <div className="modal-backdrop-custom"></div>
          <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}> {/* Ensure modal is visible when active */}
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Followup Editor</h5>
                </div>
                <div className="modal-body">

                  <div className="edit-email-form-group">
                    <label htmlFor='email-body' className="edit-email-form-label">Email Body</label>
                    <textarea
                      type="text"
                      className="edit-email-form-textarea"
                      placeholder={campaignConfiguration[followUpId] ? campaignConfiguration[followUpId] : "Write your followup here"}
                      id='email-body'
                      value={followupConfiguration.emailBody}
                      onChange={(e) => setFollowupConfiguration((prevConfig) => ({
                        ...prevConfig,
                        emailBody: e.target.value,
                      }))}
                    />
                  </div>

                  <div>
                    <EmailScheduler
                      setCampaignConfiguration={setFollowupConfiguration}
                      emailToSchedule={followUpTimeId}
                      description={`Schedule ${followUpId}`}
                      lastChosenTime={campaignConfiguration[followUpTimeId] ? campaignConfiguration[followUpTimeId].localDateFormatted : null}
                    />
                  </div>

                  <div>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i>{followupConfiguration[followUpTimeId] ? `Follow-up will be sent on ${displayDate}` : 'Follow-Up Not Scheduled'} </p>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i> Deleting a contact's followup text will remove their followup.</p>
                    <p className='modal-capsule-text'><i className="bi bi-exclamation-circle-fill"></i> Receiving a reply automatically removes followups for that contact.</p>
                  </div>


                  {fetchError && <InlineError errorMessage={fetchError} setErrorMessage={setFetchError} />}
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={() => handleFollowupRemove()} className="btn btn-primary">Remove Follow-Up</button>
                  <button type="button" onClick={() => handleColumnAdition()} className="btn btn-primary">Save Follow-up</button>
                  <button type="button" className="btn btn-secondary" onClick={() => toggleModal()}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        null
      }

    </div>
  )
}

export default FollowUpColumn
